<template>
  <div class="row">
    <div class="col-12">
      <q-chip
        v-for="(process, idx) in assessPlan.processes"
        :key="idx"
        outline square
        :removable="editable&&!disabled&&process.ramProcessAssessStepCd!=='RPA0000005'"
        :color="process.selected ? 'primary' : 'grey-6'"
        text-color="white"
        icon="memory"
        :selected.sync="process.selected"
        :label="process.processName"
        :title="process.processName"
        v-model="process.check"
        @remove="state => removeProcess(state, process)"
        @update:selected="state => selectedProcess(state, process, idx)"
      >
        <q-tooltip>{{ process.processName }}</q-tooltip>
      </q-chip>
      <q-chip
        v-if="editable&&!disabled"
        outline square
        clickable
        color="orange"
        text-color="white"
        icon="add"
        label="추가"
        title="추가"
        @click="addProcess"
      />
      <q-chip
        v-if="editable&&!disabled"
        outline square
        clickable
        color="orange"
        text-color="white"
        icon="add"
        label="직접추가"
        title="직접추가"
        @click="writeProcess"
      />
    </div>
    <div class="col-12">
      <c-tab
        type="tabcard"
        align="left"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :process.sync="process"
            :disabled.sync="disabled"
            :updateBtnData.sync="updateBtnData"
            @saveProcess="saveProcess"
          />
        </template>
      </c-tab>
    </div>
    <q-dialog v-model="processDialog.show" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <c-card title="평가대상 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled" 
                  label="저장" 
                  icon="save" 
                  @btnClicked="saveWriteProcess"/>
                <c-btn 
                  label='닫기' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processName"
                  v-model="processDialog.processName">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'checklist-target-process',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        processes: [], // 대상공정 목록
      }),
    },
    searchFlag: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      process: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        targets: [],
      },
      processDialog: {
        show: false,
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
      },
      tab: 'process',
      tabItems: [
        { name: 'process', icon: 'work_outline', label: '대상공정', component: () => import(`${'./checklistTargetProcessDetail.vue'}`) },
        { name: 'jobRisk', icon: 'work_outline', label: '평가대상파악', component: () => import(`${'./checklistTargetItem.vue'}`) },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.assessPlan.ramStepCd !== 'RRS0000001' && this.assessPlan.ramStepCd) && !this.updateBtnData.flag
    }
  },
  watch: {
    'assessPlan.processes': {
      handler: function () {
        let idx = this.$_.findIndex(this.assessPlan.processes, { processCd: this.saveProcessCd })
        if (this.saveProcessCd && idx > -1) {
          this.assessPlan.processes[idx].selected = true
        }
      },
      deep: true,
    },
    'searchFlag.value': {
      handler: function () {
        if (this.assessPlan.processes && this.assessPlan.processes.length > 0) {
          let idx = this.$_.findIndex(this.assessPlan.processes, { processCd: this.saveProcessCd })
          if (idx === -1) {
            this.selectedProcess(true, this.assessPlan.processes[0], 0)
          }
        }
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.ram.assessProcess.insert.url;
      this.deleteUrl = transactionConfig.ram.assessProcess.delete.url;
      // code setting
      // list setting
      if (this.assessPlan.processes && this.assessPlan.processes.length > 0) {
        this.selectedProcess(true, this.assessPlan.processes[0], 0)
      }
    },
    removeProcess(state, process) {
      let message = '제외하실 경우 해당 공정에 해당하는\n\r평가대상 목록도 변경됩니다.\n\r진행하시겠습니까?'
      if (this.updateBtnData.flag) {
        message = '현재 평가실행 중인 대상공정입니다.\n\r관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n\r진행하시겠습니까?'
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: message,
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: process,
          };
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('saveCallback', this.assessPlan.ramRiskAssessmentPlanId)
            Object.assign(this.$data.process, this.$options.data().process);
            /**
             * 계획정보수정
             *  변경사항을 적용하기 위함
             */
            if (this.updateBtnData.flag) {
              this.updateBtnData.research = uid();
            }
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
          process.check = true
        },
      });
    },
    addProcess() {
      this.popupOptions.title = '단위공정 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = this.$_.filter(data, (item) => {
          return this.$_.findIndex(this.assessPlan.processes, { processCd: item.processCd }) === -1
        })
        this.$_.forEach(saveData, item => {
          item.ramRiskAssessmentPlanId = this.assessPlan.ramRiskAssessmentPlanId;
          item.ramProcessAssessStepCd = 'RPA0000001';
          item.assessDeptCd = this.$store.getters.user.deptCd
          item.regUserId = this.$store.getters.user.userId;
        })
        this.$http.url = this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('saveCallback', this.assessPlan.ramRiskAssessmentPlanId)
          /**
           * 계획정보수정
           *  변경사항을 적용하기 위함
           */
          if (this.updateBtnData.flag) {
            this.updateBtnData.research = uid();
          }
        },);
      }
    },
    writeProcess() {
      /**
       * 공정을 직접 만들지만, 공정코드를 가상으로 유니크하게 셋팅
       *  :: 해당 테이블 및 이후 테이블에서 공정코드를 사용함으로 이와 같이 구성
       */
      this.processDialog.processCd = 'WTE' + uid()
      this.processDialog.show = true;
    },
    closeDialog() {
      this.processDialog.show = false;
      Object.assign(this.$data.processDialog, this.$options.data().processDialog);
    },
    saveWriteProcess() {
      let saveData = []
      if (this.$_.findIndex(this.assessPlan.processes, { processName: this.processDialog.processName }) > -1) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '동일한 공정명이 있습니다.', // 동일한 공정명이 있습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.processDialog.ramRiskAssessmentPlanId = this.assessPlan.ramRiskAssessmentPlanId;
        this.processDialog.ramProcessAssessStepCd = 'RPA0000001';
        this.processDialog.assessDeptCd = this.$store.getters.user.deptCd
        this.processDialog.regUserId = this.$store.getters.user.userId;
        saveData.push(this.processDialog)
        this.$http.url = this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('saveCallback', this.assessPlan.ramRiskAssessmentPlanId)
          this.closeDialog();

          /**
           * 계획정보수정
           *  변경사항을 적용하기 위함
           */
          if (this.updateBtnData.flag) {
            this.updateBtnData.research = uid();
          }
        },);
      }
    },
    /**
     * chip 라이브러리 사용
     * 공정을 클릭 했을 시에 해당 공정이 클릭되었음을 표시하게끔 배경색을 변경시키며
     * 다른 공정들은 selected false 처리 및 색상 primary로 변경
     */
    selectedProcess(state, process, idx) {
      if (this.saveProcessCd) {
        this.saveProcessCd = ''
      }
      if (state) {
        this.assessPlan.processes.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
            this.process = process;
          }
        })
      } else {
        this.$set(this.assessPlan.processes[idx], 'selected', true)
      }
    },
    saveProcess(processCd) {
      this.saveProcessCd = processCd;
      this.$emit('saveCallback', this.assessPlan.ramRiskAssessmentPlanId)
    },
  }
};
</script>
